import { Box, Typography, Divider, List, ListItem, Paper, Checkbox } from '@mui/material';
import React from 'react';
import { Trans } from 'react-i18next';
import MarkdownPreview from '../../markdown-preview';

interface AnswerI {
    content: string;
    correct: boolean;
}

interface PeerQuizPreviewI {
    titleText: string;
    questionText: string;
    answers: AnswerI[];
}

function PeerQuizPreview({ titleText, questionText, answers }: PeerQuizPreviewI) {
    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    flexGrow: 1,
                    bgcolor: 'background.paper',
                    p: 2,
                }}
            >
                <Typography variant="h2">
                    <Trans i18nKey="quizPreviewTitleLabel" />
                </Typography>
                <Typography
                    variant="h3"
                    component="p"
                    color={!titleText ? 'error' : undefined}
                    my={1}
                >
                    {titleText ? titleText : <Trans i18nKey="noTitle" />}
                </Typography>

                <Typography variant="h2">
                    <Trans i18nKey="quizPreviewQuestionLabel" />
                </Typography>
                <Box my={1}>
                    {questionText ? (
                        <MarkdownPreview>{questionText}</MarkdownPreview>
                    ) : (
                        <Typography color="error">
                            <Trans i18nKey="noQuestion" />
                        </Typography>
                    )}
                </Box>

                <Typography variant="h2">
                    <Trans i18nKey="quizPreviewAnswerOptionsLabel" />
                </Typography>
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    py={1}
                >
                    <Typography variant="caption">
                        <Trans i18nKey="correctAnswer" />
                    </Typography>
                    <Divider sx={{ height: 28, ml: 1 }} orientation="vertical" />
                </Box>
                <List
                    dense
                    sx={{
                        width: '100%',
                        flexGrow: 1,
                        bgcolor: 'background.paper',
                    }}
                >
                    {answers &&
                        answers.length > 0 &&
                        answers.map((value, index) => {
                            return (
                                <ListItem sx={{ px: 0 }} key={index}>
                                    <Paper
                                        component="form"
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            width: '100%',
                                        }}
                                    >
                                        {value.content ? (
                                            <Box>

                                            <MarkdownPreview>{value.content}</MarkdownPreview>
                                            </Box>
                                        ) : (
                                            <Typography
                                                sx={{ p: 1, ml: 1, flex: 1 }}
                                                color={!value.content ? 'error' : undefined}
                                            >
                                                <Trans i18nKey="noAnswerContent" />
                                            </Typography>
                                        )}

                                        <Checkbox
                                            edge="end"
                                            disableRipple
                                            sx={{ p: 1, mx: 0.5 }}
                                            checked={answers[index]?.correct}
                                        />
                                    </Paper>
                                </ListItem>
                            );
                        })}
                </List>
            </Box>
        </>
    );
}

export default PeerQuizPreview;
