import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Box, ListItemIcon, Typography } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

const submissions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((value) => `Title ${value}`);

interface PeerQuizListSelectI {
    submissions: Record<string, unknown>[] | undefined;
    selectedIndex: number;
    setSelectedIndex: (index: number) => void;
}

export default function PeerQuizListSelect({ submissions, selectedIndex, setSelectedIndex }: PeerQuizListSelectI) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    
    const open = Boolean(anchorEl);
    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
        setSelectedIndex(index);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (!submissions || submissions?.length === 0) {
        return (
            <Box width="100%" p={2}>
                <Typography>No submissions</Typography>
            </Box>
        );
    }

    return (
        <>
            <List component="nav" aria-label="Creation assignment submission selection">
                <ListItem
                    button
                    id="creation-assignment-submission-select"
                    aria-haspopup="listbox"
                    aria-controls="submission-menu"
                    aria-label="show selected creation assignment"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClickListItem}
                >
                    <ListItemText
                        primary={selectedIndex === -1 ? "Click to open quiz editor" : submissions[selectedIndex]?.title}
                        secondary={selectedIndex === -1 ? "" : new Date(submissions[selectedIndex]?.updatedAt).toLocaleDateString()}
                    />
                    <ListItemIcon>
                        <ExpandMoreIcon />
                    </ListItemIcon>
                </ListItem>
            </List>
            <Menu
                id="submission-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'submission-select',
                    role: 'listbox',
                }}
            >
                {submissions.map((submission, index) => (
                    <MenuItem
                        sx={{ width: 'calc(100vw - 32px)' }}
                        key={submission.id}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                    >
                        <ListItemText
                            primary={
                                <Typography variant="subtitle1" sx={{ color: 'inherit' }}>
                                    {submission.title}
                                </Typography>
                            }
                            secondary={
                                <Typography variant="subtitle2" sx={{ color: 'inherit' }}>
                                    {new Date(submission.updatedAt).toLocaleDateString()}
                                </Typography>
                            }
                        />
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
