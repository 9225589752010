import { Paper, Typography } from '@mui/material';
import React from 'react';
import { Trans } from 'react-i18next';
import { postCreationAssignmentSubmissionUpdate } from '../../../../http-actions/assignment-actions';
import { AuthContext } from '../../../../providers/auth-provider';
import PeerQuizEditorVertical from '../peer-quiz-editor-vertical';

export default ({
    postEditHandler,
    createdAssignment,
    creationAssignmentId,
    creationAssignmentSubmissionId,
}: {
    createdAssignment?: Record<string, unknown>;
    creationAssignmentId?: number;
    creationAssignmentSubmissionId?: number;
    postEditHandler: () => void;
}) => {
    const { state: authState } = React.useContext(AuthContext);

    if (!creationAssignmentId || !createdAssignment || !creationAssignmentSubmissionId) {
        return (
            <Paper
                sx={(theme) => ({
                    ...theme.assignmentHandout,
                })}
            >
                <Typography variant="h3" component="h2">
                    <Trans i18nKey="selectQuestionToEdit" />
                </Typography>
            </Paper>
        );
    }

    const submitCreationAssignment = async (data: any) => {
        if (!authState?.token) {
            alert('Need to be logged in to submit question.');
            return;
        }

        if (!creationAssignmentId) {
            alert('Could not find assignment for submission.');
            return;
        }

        const response = await postCreationAssignmentSubmissionUpdate(
            authState.token,
            creationAssignmentId,
            data,
            creationAssignmentSubmissionId,
        );

        if (response?.err) {
            return;
        }

        postEditHandler();

        return response;
    };

    const editorInitData = createdAssignment && {
        titleText: createdAssignment.title,
        questionText: createdAssignment.questionText,
        answers: createdAssignment.question_answer_options.map((ao) => ({
            content: ao.option_text,
            correct: ao.is_correct,
        })),
    };

    console.log('GGG', editorInitData);

    return (
        <PeerQuizEditorVertical
            initialValues={editorInitData}
            submitCreationAssignment={submitCreationAssignment}
        />
    );
};
