import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import { Trans } from 'react-i18next';
import { getCreationAssignmentSubmissionsWithQuestionContentForUser } from '../../../../http-actions/assignment-actions';
import { AuthContext } from '../../../../providers/auth-provider';
import PeerQuizEditor from './peer-quiz-editor';
import PeerQuizList from './peer-quiz-list';
import PeerQuizListSelect from './peer-quiz-list-select';

// PeerQuiz submission list and showing editor
// |---- Created assignment references -> Pass to list component
// |---- Creation assignment reference -> pass to editor

// Disable the previous assignment
// |---- Send request to new route to mark a submission as deprecated
// |----

export default () => {
    const { state: authState } = useContext(AuthContext);
    const [submissionsWithQuestion, setSubmissionWithQuestion] = useState();
    const [selectedIndex, setSelectedIndex] = useState(-1);

    useEffect(() => {
        if (authState?.token) {
            getCreationAssignmentSubmissionsWithQuestionContentForUser(authState?.token).then(
                (response) => {
                    if (!response?.err) {
                        setSubmissionWithQuestion(response);
                    }
                },
            );
        }
    }, [authState?.token]);

    const postEditHandler = () => {
        setSelectedIndex(-1);
        if (authState?.token) {
            getCreationAssignmentSubmissionsWithQuestionContentForUser(authState?.token).then(
                (response) => {
                    if (!response?.err) {
                        setSubmissionWithQuestion(response);
                    }
                },
            );
        }
    };

    const selectedSubmission =
        submissionsWithQuestion &&
        Array.isArray(submissionsWithQuestion) &&
        submissionsWithQuestion[selectedIndex];

    return (
        <Container maxWidth={'xl'}>
            <Grid container>
                <Grid item xs={12} px={2} pt={2}>
                    <Typography variant="caption">
                        <Trans i18nKey="editingQuestionConsequences" />
                    </Typography>
                </Grid>
                <Grid item xs={12} md p={2} maxHeight="calc(100vh - 128px)">
                    <Paper
                        elevation={0}
                        sx={{
                            p: 2,
                            height: '100%',
                            width: '100%',
                            display: { xs: 'none', md: 'flex' },
                            flexDirection: 'column'
                        }}
                    >
                        <Typography variant='h2' component="h1">
                            <Trans i18nKey="myQuestions" />
                        </Typography>
                        <Box flexGrow="1" overflow='auto'>
                            <PeerQuizList
                                submissions={submissionsWithQuestion}
                                selectedIndex={selectedIndex}
                                setSelectedIndex={setSelectedIndex}
                            />
                        </Box>
                    </Paper>
                    <Paper
                        elevation={0}
                        sx={{
                            p: 2,
                            width: '100%',
                            height: '100%',
                            overflowY: 'auto',
                            display: { xs: 'block', md: 'none' },
                        }}
                    >
                        <PeerQuizListSelect
                            submissions={submissionsWithQuestion}
                            selectedIndex={selectedIndex}
                            setSelectedIndex={setSelectedIndex}
                        />
                    </Paper>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={8}
                    p={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                >
                    <PeerQuizEditor
                        postEditHandler={postEditHandler}
                        createdAssignment={selectedSubmission}
                        creationAssignmentId={selectedSubmission?.creationAssignmentId}
                        creationAssignmentSubmissionId={
                            selectedSubmission?.creationAssignmentSubmissionId
                        }
                    />
                </Grid>
            </Grid>
        </Container>
    );
};
