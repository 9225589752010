import { Box, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import React from 'react';

interface PeerQuizListI {
    submissions: Record<string, unknown>[] | undefined;
    selectedIndex: number;
    setSelectedIndex: (index: number) => void;
}

export default ({ submissions, selectedIndex, setSelectedIndex }: PeerQuizListI) => {
    if (!submissions || submissions?.length === 0) {
        return (
            <Box width="100%" p={2}>
                <Typography>No submissions</Typography>
            </Box>
        );
    }

    return (
        <List sx={{ width: '100%' }}>
            {submissions.map((submission, index) => {
                return (
                    <ListItem key={submission.id} disablePadding>
                        <ListItemButton
                            selected={selectedIndex === index}
                            onClick={() => {
                                if (selectedIndex === index) {
                                    setSelectedIndex(-1);
                                    return;
                                }
                                setSelectedIndex(index);
                            }}
                        >
                            <ListItemText
                                sx={{
                                    color: (theme) =>
                                        selectedIndex > -1 && selectedIndex !== index
                                            ? theme.palette.grey[400]
                                            : 'inherit',
                                }}
                                primary={
                                    <Typography variant="subtitle1" sx={{ color: 'inherit' }}>
                                        {submission.title}
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant="subtitle2" sx={{ color: 'inherit' }}>
                                        {new Date(submission.updatedAt).toLocaleDateString()}
                                    </Typography>
                                }
                            />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    );
};
