import React from 'react';
import { Box } from '@mui/material';
import PeerQuizLayout from '../../components/assignment/peer-quiz/view/peer-quiz-layout';

export default () => {
    return (
        <Box width="100%" display="flex" flexDirection="column" alignItems="center">
            <PeerQuizLayout />
        </Box>
    );
};
