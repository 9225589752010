import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Dialog, Divider, InputBase, Link, List, ListItem, Paper, Tab, Tabs } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import { Trans, useTranslation } from 'react-i18next';

import MarkdownPreview from '../../markdown-preview';

const AceEditor = typeof window !== 'undefined' ? require('react-ace').default : Box;

import IconButton from '@mui/material/IconButton';
import CustomButton from '../../button';
import PeerQuizPreview from './peer-quiz-preview';

interface AnswerI {
    content: string;
    correct: boolean;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function a11yProps(index: number) {
    return {
        id: `response-activity-tab-${index}`,
        'aria-controls': `response-activity-tabpanel-${index}`,
    };
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            display={value !== index ? 'none' : 'flex'}
            flexGrow={1}
            height="100%"
            width="100%"
            flexDirection="column"
            overflow="hidden"
            role="tabpanel"
            id={`response-activity-tabpanel-${index}`}
            aria-labelledby={`response-activity-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </Box>
    );
}

function defaultValuesAreDifferentFromCurrentValues(
    original: Record<string, unknown | Array<unknown>>,
    changed: Record<string, unknown | Array<unknown>>,
) {
    if (original.titleText !== changed.titleText) return true;
    if (original.questionText !== changed.questionText) return true;
    return original.answers.some((value, index) => {
        return (
            value?.content !== changed.answers[index]?.content ||
            value?.correct !== changed.answers[index]?.correct
        );
    });
}

export default function PeerQuizEditorVertical({
    initialValues,
    submitCreationAssignment,
}: {
    initialValues?: {
        titleText: string;
        questionText: string;
        answers: AnswerI[];
    };
    submitCreationAssignment: (data: any) => {};
}) {
    const { t } = useTranslation();

    // Set default values
    const defaultValues = initialValues
        ? initialValues
        : {
              titleText: '',
              questionText: '',
              answers: [
                  { content: '', correct: false },
                  { content: '', correct: false },
              ],
          };

    useEffect(() => {
        setTitleText(defaultValues.titleText);
        setQuestionText(defaultValues.questionText);
        setAnswers(defaultValues.answers);
    }, [initialValues?.titleText, initialValues?.questionText]);

    const [previewOpen, setPreviewOpen] = React.useState(false);
    const [isPostSubmit, setIsPostSubmit] = React.useState(false);

    const handlePostSubmit = () => {
        setIsPostSubmit(true);
    };

    const handleSubmit = async () => {
        if (!questionText || !titleText || answers.some((a) => !a.content)) {
            const alertText = t('quizMissingContent');
            alert(alertText);
            return;
        }

        const submitText = t('confirmQuizCreation');
        const submitConfirmed = confirm(submitText);
        if (!submitConfirmed) return;

        // TODO: check for empty answer strings
        const data = {
            question: {
                title: titleText,
                text: questionText,
                answers: answers.map((a) => ({ text: a.content, correct: a.correct })),
            },
        };

        const response = await submitCreationAssignment(data);

        if (response?.err) return;

        handlePostSubmit();
    };

    const handleReset = () => {
        setTitleText(defaultValues.titleText);
        setQuestionText(defaultValues.questionText);
        setAnswers(defaultValues.answers);
        setQuestionTabValue(0);
        setAnswerTabValue(0);
        setIsPostSubmit(false);
    };

    const [titleText, setTitleText] = React.useState(defaultValues.titleText);

    const handleTitleText = (event) => {
        setTitleText(event.target.value);
    };

    // Question state

    const [questionText, setQuestionText] = React.useState(defaultValues.questionText);

    const handleAceChange = (value: string) => {
        setQuestionText(value);
    };

    const [questionTabValue, setQuestionTabValue] = React.useState(0);

    const handleQuestionTabChange = (_event: React.ChangeEvent<unknown>, newValue: number) => {
        setQuestionTabValue(newValue);
    };

    // Answer state

    const [answers, setAnswers] = React.useState<AnswerI[]>(defaultValues.answers);

    const createAnswer = () => {
        const newAnswer = { content: '', correct: false };
        const newAnswers = answers.concat(newAnswer);
        setAnswers(newAnswers);
    };

    const handleAnswerTextAce = (indexToChange: number) => (value: string) => {
        const newAnswers = answers.map((answer, index) =>
            index === indexToChange ? { ...answer, content: value } : answer,
        );
        setAnswers(newAnswers);
    };

    const deleteAnswer = (indexToDelete: number) => {
        if (answers.length < 3) {
            const alertText = t('minimumTwoAnswerOptions');
            alert(alertText);
            return;
        }
        const confirmText = t('answerDeletionConfirmation');
        const confirmedDeletion = confirm(confirmText);
        if (!confirmedDeletion) return;
        const newAnswers = answers.filter((_, index) => index !== indexToDelete);
        setAnswers(newAnswers);
    };

    const [answerTabValue, setAnswerTabValue] = React.useState(0);

    const handleAnswerTabChange = (_event: React.ChangeEvent<unknown>, newValue: number) => {
        setAnswerTabValue(newValue);
    };

    const handleToggle = (indexToChange: number) => () => {
        const newAnswers = answers.map((answer, index) =>
            index === indexToChange ? { ...answer, correct: !answer.correct } : answer,
        );
        setAnswers(newAnswers);
    };

    const disableSubmit =
        !questionText ||
        !titleText ||
        answers.some((a) => !a.content) ||
        !defaultValuesAreDifferentFromCurrentValues(defaultValues, {
            titleText,
            questionText,
            answers,
        });

    if (isPostSubmit) {
        return (
            <Box
                className="feedback-target"
                sx={{
                    backgroundColor: 'white',
                    maxWidth: '1000px',
                    width: '100%',
                    height: '400px',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 2,
                }}
            >
                <Box display="flex" flexGrow="1" justifyContent="center" alignItems="center">
                    <Typography variant="h3" component="p" sx={{ mt: 2, mb: 1 }}>
                        <Trans i18nKey="createdQuizSubmitted" />
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifySelf: 'flex-end',
                        flexDirection: 'row',
                        pt: 2,
                    }}
                >
                    <Box sx={{ flex: '1 1 auto' }} />
                    <CustomButton kind="fitech" onClick={handleReset}>
                        <Trans i18nKey="createNewQuiz" />
                    </CustomButton>
                </Box>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                backgroundColor: 'white',
                maxWidth: '1000px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                p: 2,
            }}
        >
            <Dialog open={previewOpen} onClose={() => setPreviewOpen(false)}>
                <Box
                    sx={{
                        backgroundColor: 'white',
                        width: '100%',
                        minWidth: '500px',
                        display: 'flex',
                        flexDirection: 'column',
                        p: 2,
                    }}
                >
                    <PeerQuizPreview
                        questionText={questionText}
                        titleText={titleText}
                        answers={answers}
                    />
                    <Divider sx={{ my: 2 }} />
                    <Box
                        sx={{
                            display: 'flex',
                            justifySelf: 'flex-end',
                            flexDirection: 'row',
                            pt: 2,
                        }}
                    >
                        <Box sx={{ flex: '1 1 auto' }} />
                        <CustomButton
                            kind="fitech"
                            variant="contained"
                            onClick={() => setPreviewOpen(false)}
                        >
                            <Trans i18nKey="close" />
                        </CustomButton>
                    </Box>
                </Box>
            </Dialog>
            <Box className="feedback-target" px={2} display="flex" flexGrow="1" flexDirection="column">
                <Typography my={2} variant="caption">
                    <Trans i18nKey="markdownUsedForQuestionCreation" />{' '}
                    <Link target="_blank" href="https://www.markdownguide.org/basic-syntax/">
                        Markdown Guide
                    </Link>
                    .
                </Typography>

                <Typography variant="h2">
                    <Trans i18nKey="quizPreviewTitleLabel" />
                </Typography>
                <Box display="flex" flexGrow="1" alignItems="center" mb={4}>
                    <InputBase
                        autoFocus
                        sx={{ flex: 1, fontSize: 24 }}
                        placeholder={t('questionTitlePlaceholder')}
                        inputProps={{ 'aria-label': t('questionTitlePlaceholder'), maxLength: 100 }}
                        onChange={handleTitleText}
                        value={titleText}
                    />
                </Box>

                <Typography variant="h2">
                    <Trans i18nKey="quizPreviewQuestionLabel" />
                </Typography>
                <Box mb={4}>
                    <Box component="nav" mb={2}>
                        <Tabs
                            value={questionTabValue}
                            onChange={handleQuestionTabChange}
                            indicatorColor="primary"
                            aria-label="new question editor options"
                        >
                            <Tab
                                color="inherit"
                                label={t('writerTabLabelText')}
                                {...a11yProps(0)}
                            />
                            <Tab
                                color="inherit"
                                label={t('previewTabLabelText')}
                                {...a11yProps(1)}
                            />
                        </Tabs>
                    </Box>
                    <TabPanel value={questionTabValue} index={0}>
                        <Box display="flex" flexDirection="column" flexGrow={1} width="100%">
                            <AceEditor
                                value={questionText}
                                onChange={handleAceChange}
                                theme="github"
                                mode="markdown"
                                fontSize="18px"
                                minLines={10}
                                height="400px"
                                width="100%"
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value={questionTabValue} index={1}>
                        <Box display="flex" flexDirection="column" width="100%">
                            {questionText !== '' || questionText ? (
                                <Box>
                                    <MarkdownPreview>{questionText}</MarkdownPreview>
                                </Box>
                            ) : (
                                <Typography color="error">
                                    <Trans i18nKey="noQuestion" />
                                </Typography>
                            )}
                        </Box>
                    </TabPanel>
                </Box>

                <Typography variant="h2">
                    <Trans i18nKey="quizPreviewAnswerOptionsLabel" />
                </Typography>
                <>
                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-end"
                    >
                        <Box component="nav" mb={1}>
                            <Tabs
                                value={answerTabValue}
                                onChange={handleAnswerTabChange}
                                indicatorColor="primary"
                                aria-label="new answer editor options"
                            >
                                <Tab
                                    color="inherit"
                                    label={t('writerTabLabelText')}
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    color="inherit"
                                    label={t('previewTabLabelText')}
                                    {...a11yProps(1)}
                                />
                            </Tabs>
                        </Box>
                        <Box display="flex" px={1}>
                            <Typography variant="caption">
                                <Trans i18nKey="correctAnswer" />
                            </Typography>
                            <Divider
                                sx={{ height: 28, mx: 1, color: 'black' }}
                                orientation="vertical"
                            />
                            <Typography width="40px" variant="caption">
                                <Trans i18nKey="deleteColumnText" />
                            </Typography>
                        </Box>
                    </Box>
                    <List
                        sx={{
                            width: '100%',
                            flexGrow: 1,
                            bgcolor: 'background.paper',
                        }}
                    >
                        {answers.map((value, index) => {
                            const labelId = `answer-list-checkbox-${index}`;
                            const textLabelId = `answer-list-textfield-${index}`;
                            return (
                                <ListItem key={index} disablePadding>
                                    <Paper
                                        component="form"
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                            height: '100%',
                                            mb: 2,
                                        }}
                                    >
                                        <TabPanel value={answerTabValue} index={0}>
                                            <AceEditor
                                                value={value.content}
                                                onChange={handleAnswerTextAce(index)}
                                                theme="github"
                                                mode="markdown"
                                                fontSize="18px"
                                                minLines={10}
                                                height="200px"
                                                width="100%"
                                            />
                                        </TabPanel>

                                        <TabPanel value={answerTabValue} index={1}>
                                            {value.content ? (
                                                <Box px={2}>
                                                    <MarkdownPreview>
                                                        {value.content}
                                                    </MarkdownPreview>
                                                </Box>
                                            ) : (
                                                <Box
                                                    px={2}
                                                    display="flex"
                                                    alignItems="center"
                                                    height="60px"
                                                >
                                                    <Typography
                                                        color={!value.content ? 'error' : undefined}
                                                    >
                                                        <Trans i18nKey="noAnswerContent" />
                                                    </Typography>
                                                </Box>
                                            )}
                                        </TabPanel>

                                        <Box display="flex" p={1}>
                                            <Checkbox
                                                edge="end"
                                                sx={{ p: 1, mx: 0.5 }}
                                                onChange={handleToggle(index)}
                                                checked={answers[index]?.correct}
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                            <Divider
                                                sx={{ height: 28, m: 1 }}
                                                orientation="vertical"
                                            />
                                            <IconButton
                                                color="secondary"
                                                sx={{ p: 1 }}
                                                aria-label={t('deleteColumnText')}
                                                onClick={() => deleteAnswer(index)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    </Paper>
                                </ListItem>
                            );
                        })}

                        <ListItem disablePadding key={answers.length + 1}>
                            <Paper
                                component={Button}
                                elevation={0}
                                disableElevation
                                sx={{
                                    height: 60,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    textTransform: 'none',
                                    backgroundColor: 'white',
                                    outlineStyle: 'solid',
                                    outlineColor: '#F0F0F0',
                                    outlineWidth: '1px',
                                    color: 'rgba(0, 0, 0, 0.6)',
                                    '&:hover': {
                                        boxShadow: (theme) => theme.shadows[1],
                                        backgroundColor: 'white',
                                    },
                                }}
                                onClick={createAnswer}
                            >
                                <Typography>
                                    <Trans i18nKey="addAnswerOptionButtonText" />
                                </Typography>
                            </Paper>
                        </ListItem>
                    </List>
                </>
                <Divider sx={{ my: 2 }} />
                <Box
                    sx={{
                        display: 'flex',
                        justifySelf: 'flex-end',
                        flexDirection: 'row',
                        pt: 2,
                    }}
                >
                    <Box sx={{ flex: '1 1 auto' }} />
                    <CustomButton sx={{ mr: 2 }} kind="fitech" onClick={() => setPreviewOpen(true)}>
                        <Trans i18nKey="preview" />
                    </CustomButton>
                    <CustomButton
                        kind="fitech"
                        variant="contained"
                        disabled={disableSubmit}
                        onClick={handleSubmit}
                    >
                        <Trans i18nKey="submit" />
                    </CustomButton>
                </Box>
            </Box>
        </Box>
    );
}
